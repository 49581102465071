import { Profile, ProfileConfig } from '@cca/util';

import { Environment } from '../../environment.types';

import development from './development';
import local from './local';
import production from './production';
import qa from './qa';

const profileConfig: ProfileConfig<Environment> = {
  name: Profile.mmbenl,
  local,
  development,
  qa,
  production,
};

export default profileConfig;
