import env from '@beam-australia/react-env';

import { Env, Profile, selectEnvironment } from '@cca/util';

import mmatProfile from './profiles/mmat';
import mmbeFrProfile from './profiles/mmbe-fr';
import mmbeNlProfile from './profiles/mmbe-nl';
import mmdeProfile from './profiles/mmde';
import mmesProfile from './profiles/mmes';
import mmnlProfile from './profiles/mmnl';
import sedeProfile from './profiles/sede';

const environment = selectEnvironment(
  {
    mmde: mmdeProfile,
    sede: sedeProfile,
    mmes: mmesProfile,
    mmat: mmatProfile,
    mmnl: mmnlProfile,
    mmbenl: mmbeNlProfile,
    mmbefr: mmbeFrProfile,
  },
  env('CCA_PROFILE') as Profile,
  (env('CCA_ENV') as Env) || 'local',
);

export default environment;
