import { Profile } from '@cca/util';

import { Environment } from '../../environment.types';

const environment: Environment = {
  isDevelopment: true,
  isQa: false,
  isProduction: false,

  profile: Profile.mmbefr,

  chatbotUrl: 'http://localhost:3000',
  integrationUrl: 'http://localhost:3000/integration',
  middlewareUrl: 'http://localhost:3000/api',
  theme: 'mm',
  showWelcomeMessage: true,
  welcomeMessageOptions: {
    delayInSeconds: 3,
    minTimeDistanceInHours: 0.00001,
  },
};

export default environment;
